import React, { useContext } from 'react';
import { store } from '../store/store';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const Hero = styled(motion.img)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
`;

const FullScreenProject = () => {
  const { state, dispatch } = useContext(store);

  return (
    <Hero
      layoutId="hero"
      animate={{ filter: 'grayscale(1)' }}
      transition={{ ease: [0.165, 0.84, 0.44, 1], duration: 0.75 }}
      src={state?.activeProject}
    />
  );
};

export default FullScreenProject;
