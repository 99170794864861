import React, { useContext } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { store } from '../store/store';

const Wrapper = styled(motion.header)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 4rem 6rem;
  text-transform: uppercase;

  a {
    border-bottom: 1px solid;
  }

  nav a {
    margin: 0 1.6rem;
  }
`;

export const variants = {
  initial: {
    opacity: 0,
    y: -30,
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      ease: 'easeOut',
      duration: 0.5,
      delay: 0.5,
    },
  },
};

const Header = () => {
  const { state, dispatch } = useContext(store);
  return (
    <Wrapper
      variants={variants}
      initial="initial"
      animate={state.introIsReady ? 'enter' : {}}
    >
      <p>© 2020, Kalok</p>
      <nav>
        <a>Instagram</a>
        <a>Dribbble</a>
        <a>Are.na</a>
      </nav>
      <a>Contact</a>
    </Wrapper>
  );
};

export default Header;
