const theme = {
  // Colours
  primaryColor: '#000',
  grey: '#B2B2B2',
  red: '#8E0306',
  beige: '#F9F6EF',
  peach: '#EED2B7',
  orange: '#CF3C1A',

  // Fonts
  fontSize: '1.8rem',
  fontFamily:
    '"FoundersGrotesk", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  fontFamilyHeading:
    '"LaPontaise", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',

  // Sizing
  maxContainerWidth: '1160px',

  // Breakpoints
  //media queries
  respondTo: {
    desktopExtraBig: `min-width: 1600px`,
    desktopSuperBig: `min-width: 1400px`,
    desktopBig: `min-width: 1280px`,
    desktop: `min-width: 1024px`,
    tablet: `min-width: 768px`,
    mobile: `max-width: 480px`,
  },
};

export default theme;
